<template>
    <div class="shopStoreBody" :class="{index: type === 1}">
        <div class="storeInfo">
            <div class="content">
                <div class="head">
                    <img src="../../../assets/img/store_title_bg.png" class="bg" />
                    <img src="../../../assets/img/store_title_icon.png" class="icon" />
                </div>

                <div class="list">
                    <div class="title">
                        <img src="../../../assets/img/shop_order.png" />
                        <h1>{{storeName}}</h1>
                    </div>

                    <div class="btnGroup">
                        <a href="javascript:void(0)" class="license" @click="showLicense">
                            <img src="../../../assets/img/license.png" />
                            <span>{{isLogin ? '证照信息' : '登录可见'}}</span>
                        </a>

                        <a href="javascript:void(0)" class="keep" @click="toCollect">
                            <img src="../../../assets/img/store_keep_yes.png" v-if="isCollect" />
                            <img src="../../../assets/img/store_keep_no.png" v-else />
                            <span>{{isCollect ? '已收藏' : '收藏店铺'}}</span>
                        </a>
                    </div>
                </div>

                <div class="list else">
                    <div class="title">
                        <h1>联系方式</h1>
                    </div>
                    <div class="desc">
                        <div class="line">联系电话：<span>{{isLogin ? corporatePhone : '登录可见'}}</span></div>
                    </div>
                </div>

                <div class="list else">
                    <div class="title">
                        <h1>经营范围</h1>
                    </div>
                    <div class="desc">
                        <div class="line"><span>{{legalBusinessScope}}</span></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="storeFloor shop" v-if="type === 1">
            <template v-if="floor.length > 0">
                <div class="floorItem" v-for="(item, i) in floor" :key="i">
                    <div class="title">{{JSON.parse(item.textArea).header.title}}</div>

                    <div class="goods">
                        <a href="javascript:void(0)" class="item" v-for="(good, index) in JSON.parse(item.textArea).goodsList" :key="i + '-' + index" @click="goLink(good.type, good.url)">
                            <div class="image">
                                <el-image :src="good.img" fit="contain"></el-image>
                            </div>

                            <span class="name">{{good.name}}</span>

                            <span class="price">¥ {{good.price}}</span>
                        </a>
                    </div>
                </div>
            </template>

            <noDataView v-if="floor.length === 0" />
        </div>

        <div class="storeFloor shop" v-else>
            <template v-if="goodsList.length > 0">
                <div class="floorItem">
                    <div class="goods">
                        <a href="javascript:void(0)" class="item" v-for="(good, index) in goodsList" :key="index" @click="$router.push({path: '/shop/detail', query: {id: good.id}})">
                            <div class="image">
                                <el-image :src="imageURL + good.imageUrl" fit="contain"></el-image>
                            </div>

                            <span class="name">{{good.name}}</span>

                            <span class="price">¥ {{good.price}}</span>
                        </a>
                    </div>
                </div>
            </template>

            <noDataView v-if="goodsList.length === 0" />
        </div>


        <imageViewer v-if="showViewer" :on-close="closeViewer" :url-list="showImageList" />
    </div>
</template>

<script>
  import {imageURL} from "../../../utils/config";
  import {mapState} from "vuex";
  const imageViewer = () => import('../../../components/imageViewer');
  const noDataView = () => import('../../../components/noDataView');

  export default {
    name: "shopStoreGoods",
    data () {
      return {
        imageURL: imageURL,
        showViewer: false,
        prevOverflow: '',
      }
    },
    props: {
      type: {
        type: Number,
        default: 1, // 1-首页，2-分类，3-搜搜
      },
      corporatePhone: {
        type: String,
        default: ''
      },
      legalBusinessScope: {
        type: String,
        default: ''
      },
      storeName: {
        type: String,
        default: ''
      },
      showImageList: {
        type: Array,
        default: () => {
          return []
        }
      },
      isCollect: {
        type: Boolean,
        default: false
      },
      floor: {
        type: Array,
        default: () => {
          return []
        }
      },
      goodsList: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
      ...mapState({
        isLogin: state => state.user.isLogin,
      })
    },
    created () {
    },
    methods: {
      toChangeDesc () {},
      toChangePrice () {},
      showLicense () {
        if (!this.isLogin) {
          return false;
        }
        this.prevOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        this.showViewer = true
      },
      closeViewer () {
        document.body.style.overflow = this.prevOverflow;
        this.showViewer = false
      },
      goLink (type, url) {
        if (type === '1') {
          window.open(url)
        }

        if (type === '2') {
          this.$router.push({path: '/shop/detail', query: {id: url}})
        }

        if (type === '3') {
          const { href } = this.$router.resolve({path: '/shop/list', query: {type: '2', id: url, page: '1'}});
          window.location.href = href;
        }

        if (type === '4') {
          this.$router.push({path: '/shop/store', query: {id: url}})
        }
      },
      toCollect () {
        this.$emit('toCollect')
      }
    },
    components: {
      imageViewer, noDataView
    }
  }
</script>

<style lang="scss" scoped>
    @import "../../../assets/style/store";
</style>
